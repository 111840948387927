import * as React from 'react';
import dayjs from 'dayjs';
import { Job } from '../../utils/graphql';
import Card from '../Card';
import { WHITE_ON_BLUE } from '../Colors';
import Tags from '../Tags';
import Cities from './Cities';
import Contracts from './Contracts';

type JobCardProps = {
  job: Job;
};

const JobCard: React.VoidFunctionComponent<JobCardProps> = ({
  job: { title, domains, cities, contracts, slug, date, body },
}) => {
  return (
    <article itemScope itemType="https://schema.org/JobPosting">
      {body && <meta itemProp="description" content={body} />}
      <meta itemProp="datePosted" content={dayjs(date).format('YYYY-MM-DD')} />
      <link itemProp="hiringOrganization" href="https://staging.reacteev.com/#organization" />
      <Card title={title} linkTo={slug} themeClassName={WHITE_ON_BLUE} withMicroData>
        <div className="flex flex-col md:flex-row md:justify-end">
          <h3 className="m-0 grow text-left text-2xl font-semibold sm:text-3xl" itemProp="title">
            {title}
          </h3>
          <div className="my-4 flex flex-wrap md:my-0 md:items-center md:justify-end">
            <Tags tags={domains} />
          </div>
        </div>
        <div className="text-xl font-medium">
          <Contracts contracts={contracts} withMicroData />
          {cities.length > 0 && contracts.length > 0 ? ', ' : ''}
          <Cities cities={cities} withMicroData />
        </div>
      </Card>
    </article>
  );
};

export default JobCard;
